export const tokenAddress = "0x4c7a274eae29d32ac31a739b2edeee5d4f75d7a0";
export const BusdAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
export const UsdtAddress = "0x55d398326f99059fF775485246999027B3197955"
export const contracts = {
    token:"0x4c7a274eae29d32ac31a739b2edeee5d4f75d7a0",
    ico:"0xF402ED19ce4490910EfF9110CE371De31Da18c63"
}

// test net details

// export const tokenAddress = "0xD788f3D5c24671bB4B5C7bA1A0f35CfA656438C5";
// export const BusdAddress = "0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47";
// export const UsdtAddress = "0x5E8bDA441e8b3d25D38b1Dba4B071eFc34cB991c"
// export const contracts = {
//     token:"0xD788f3D5c24671bB4B5C7bA1A0f35CfA656438C5",
//     ico:"0x446881d466e52AFF6d08B1bA976e5FfeBFccc595"
// }