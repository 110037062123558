import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";

import MainLayout from "layouts/Main";
import DashboardLayout from "layouts/Dashboard";
import Presalepage from "./pages/Presalepage";
import Adminpage from "pages/Adminpage";
// import Devpage from "pages/Devpage";
import Loading from "components/Loading";
import AdminGuard from "guards/AdminGuard";
import PrivateSale from "pages/PrivateSale";


const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Presalepage/> },
        { path: "/home", element: <PrivateSale/> },
        {
          path: "/admin",
          element: (
            <AdminGuard>
              <Adminpage />
            </AdminGuard>
          ),
        },
      ],
    },
  ]);
}

// IMPORT COMPONENTS
