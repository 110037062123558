import { useRef, useEffect, useState } from "react";
// material
import {
  Box,
  Stack,
  Typography,
  Container,
  InputBase,
  Button,
  LinearProgress,
  linearProgressClasses,
  CircularProgress
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import TokenCountDown from "components/TokenCountDown";
import { useNoWalletContract } from "hooks/useContract";
import { contracts, BusdAddress, UsdtAddress } from "utils/address";
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";
import { toast } from "react-toastify";
import BYBICO from '../abis/BYBICO.json';
import ByBarterCoin from '../abis/ByBarterCoin.json';
import ConnectButton from "components/ConnectButton";

const zoomIn = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#2c595a",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#f3ba2e",
  },
}));

export default function Presalepage() {
  const { account, library } = useEthers();
  const [amount, setAmount] = useState(1);
  const [whitelisted, setWhitelisted] = useState(false);
  const [bnbRate, setBnbRate] = useState(0);
  const [usdtRate, setUsdtRate] = useState(0);
  const [rate, setRate] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [hardCap, setHardCap] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contribution, setContribution] = useState(0);
  const [claimed, setClaimed] = useState(false);
  const [nextClaimDate, setNextClaimDate] = useState(0);
  const [nextClaimAmount, setNextClaimAmount] = useState(0);
  const [currency, setCurrency] = useState("BNB");
  const [isApproved, setIsApproved] = useState(false);
  const [lastClaimBlock, setLastClaimBlock] = useState(0);

  const icoContract = useNoWalletContract(BYBICO.abi, contracts.ico);
  const busdContract = useNoWalletContract(ByBarterCoin.abi, BusdAddress);
  const usdtContract = useNoWalletContract(ByBarterCoin.abi, UsdtAddress);

  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const handleRate = () => {
    try {
      setIsApproved(false);
      if(currency == "BNB"){
        setRate(bnbRate)
      } else {
        setRate(usdtRate);
      }
    } catch (error) {
      
    }
  }

  const handleChangeAmount = (type) => {
    if(type == 'minus'){
      if(amount > 1) {
        setAmount(Number(amount)-1)
      }
    } else if( type == 'plus'){
      setAmount(Number(amount)+1)
    }
  }

  const setNewTime = () => {
    const startTimet = Date.now();
    var lastClaimedTime = lastClaimBlock * 1000;
    lastClaimedTime = lastClaimedTime + 10368000 * 1000;
    const endTimet = nextClaimDate * 1000 + 8726400 * 1000;
    const sT = startTimet;
    const eT = endTimet > lastClaimedTime ? endTimet : lastClaimedTime;
    let distanceToNow = 0;

    if(new Date() >= startTimet){
      distanceToNow = endTimet - new Date();
    } else {
      distanceToNow = startTimet - new Date();
    }

    const getDays = Math.floor(distanceToNow / (1000 * 60 * 60 * 24));
    const getHours = `0${Math.floor(
      (distanceToNow % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )}`.slice(-2);
    const getMinutes = `0${Math.floor(
      (distanceToNow % (1000 * 60 * 60)) / (1000 * 60)
    )}`.slice(-2);
    const getSeconds = `0${Math.floor(
      (distanceToNow % (1000 * 60)) / 1000
    )}`.slice(-2);

    setCountdown({
      days: getDays || "00",
      hours: getHours || "00",
      minutes: getMinutes || "00",
      seconds: getSeconds || "00",
    });

  };


  const buy = async () => {
    try {
      setIsLoading(true);

      const weiAmount = ethers.utils.parseEther(amount.toString());

      if(currency === "BNB") {
        await icoContract.buyTokens({value: weiAmount}).then((tx) => {
          return tx.wait().then((receipt) => {
              fetchData();
              setAccountInfo()
              setIsLoading(false)
          })
        })
      } else if(currency === "BUSD" || currency === "USDT") {
        const tokenAddress = currency == "BUSD" ? BusdAddress : UsdtAddress;
        await icoContract.buyTokenWithToken(tokenAddress, weiAmount).then(tx => {
          return tx.wait().then(receipt => {
            fetchData();
            setAccountInfo()
            setIsLoading(false);
            setIsApproved(false);
          })
        })
      }

    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Try again later.");
    }
  }

  const claim = async () => {
    try {
      setIsLoading(true);
      await icoContract.claim().then((tx) => {
        return tx.wait().then((receipt) => {
          fetchData()
          setAccountInfo();
          setIsLoading(false);
        })
      })
    } catch (error) {
      fetchData();
      setIsLoading(false);
      toast.error('Could not claim at this time. Try Again later.')
      console.log(error)
    }
  }

  const approve = async () => {
    try {
      if(currency == "BNB") throw "Currency is BNB";
      const tokenContract = currency == "BUSD" ? busdContract : usdtContract;
      setIsLoading(true) 

      let allowance = await tokenContract.allowance(account, contracts.ico);
      let decimal = await tokenContract.decimals();

      if(parseFloat(allowance) >= amount * 10**decimal) {
        setIsApproved(true);
        setIsLoading(false);
      } else {
        await tokenContract.approve(contracts.ico, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
          .then(tx => {
            return tx.wait().then(receipt => {
              setIsApproved(true);
              setIsLoading(false);
            })
          })
      }

    } catch (error) {
      console.log('error', error)
      setIsLoading(false)
    }
  }

  const ProgressLoader = () => {
        
    return (
        isLoading
        ?
        <CircularProgress className="progress" />
        :''
    )
  }

  const setAccountInfo = async () => {
    try {

      setIsLoading(true);
      const icoListed = await icoContract.whitelists(account); 
      const cont = await icoContract.tokenContributions(account);  
      const eClaimed = await icoContract.withdrawn(account);
      
      setContribution(ethers.utils.formatEther(cont));
      setWhitelisted(icoListed);
      setClaimed(eClaimed);
      setIsLoading(false);

      const claimDate = await icoContract.nextClaimableBlock(account);
      const claimAmount = await icoContract.nextClaimAmount(account);
      const lastClaimedTime = await icoContract.lastClaimedBlock(account);
      setNextClaimDate(parseFloat(claimDate));
      setNextClaimAmount(parseFloat(ethers.utils.formatEther(claimAmount)));
      setLastClaimBlock(parseFloat(lastClaimedTime));
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const timing = await icoContract.presaleTiming();
      const info = await icoContract.generalIfo();
      const rt = await icoContract.rate();
      const usdtRt = await icoContract.rateUsdt();
      const hCap = ethers.utils.formatEther(info[1]);

      let tSold = await icoContract.totalSold();
      tSold = ethers.utils.formatEther(tSold);
      
      
      setBnbRate(parseFloat(rt))
      setUsdtRate(parseFloat(usdtRt));
      setTotalSold(parseFloat(tSold));
      setHardCap(parseFloat(hCap));
      setStartTime(parseInt(timing[0]));
      setEndTime(parseInt(timing[1]));
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    handleRate();
  }, [currency, bnbRate])

  useEffect(() => {
    fetchData();
    setAccountInfo();
  },[account])
  
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setNewTime(), 1000);
    return () => clearInterval(interval);
  },[nextClaimDate]);

  return (
    <Box
      sx={{
        // height: "calc(100vh - 64px)",
        // background: "url(/images/about4.png)",
        backgroundAttachment: "fixed",
        position: "relative",
        // backgroundSize: "100% 120%",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 1,
          height: 1,
          bgcolor: "black",
          opacity: 0.77,
        }}
      />
      <Container
        maxWidth="lg"
        sx={{ position: "relative", zIndex: 1, height: 1 }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: 1, height: 1 }}
        >
          <Stack
            alignItems="center"
            textAlign={'center'}
            sx={{ width: 1, pt: 2, pb: 5 }}
          >
          <Box component="img" src="/logo.png" sx={{height:'100px'}} />
            <Typography fontWeight="bold" fontSize={40} color="#f3ba2e">
                BYB PRIVATE SALE ROUND
            </Typography>
            <LinearProgress />

            <Stack sx={{ mt: 4 }}>
              {/* Presale countdown */}

              {
                new Date <= (endTime*1000)
                ?
                <TokenCountDown startTime={startTime*1000} endTime={endTime*1000}/>
                :''
              }
            </Stack>

              <Stack
                 direction="row"
                 alignItems="center"
                 spacing={1}
                 sx={{p: "6px", mt: 3}}
              >
                <Button
                  onClick={() => setCurrency("BNB")}
                  sx={{
                    border: "1px solid #0a6330",
                    bgcolor: currency == "BNB"?"#f3ba2e":"#0e1f13",
                    color:'#fff',
                    minWidth: 52,
                    height: 52,
                    borderRadius: 0,
                    fontSize: 20,
                  }}
                >
                  BNB
                </Button>
                <Button
                  onClick={() => setCurrency("BUSD")}
                  sx={{
                    border: "1px solid #0a6330",
                    bgcolor: currency == "BUSD"?"#f3ba2e":"#0e1f13",
                    color:'#fff',
                    minWidth: 52,
                    height: 52,
                    borderRadius: 0,
                    fontSize: 20,
                  }}
                >
                  BUSD
                </Button>
                <Button
                  onClick={() => setCurrency("USDT")}
                  sx={{
                    border: "1px solid #0a6330",
                    bgcolor: currency == "USDT"?"#f3ba2e":"#0e1f13",
                    color:'#fff',
                    minWidth: 52,
                    height: 52,
                    borderRadius: 0,
                    fontSize: 20,
                  }}
                >
                  USDT
                </Button>
              </Stack>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  bgcolor: "#0e1f13",
                  border: "1px solid #0a6330",
                  p: "6px",
                }}
                >
                <Button
                  onClick={() => handleChangeAmount("minus")}
                  sx={{
                    minWidth: 36,
                    height: 36,
                    bgcolor: "black",
                    borderRadius: 0,
                    fontSize: 30,
                  }}
                >
                  -
                </Button>
                <InputBase
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  inputProps={{ sx: { textAlign: "center" } }}
                  sx={{ height: 36, width: 160, fontWeight: "bold" }}
                />
                <Button
                  onClick={() => handleChangeAmount("plus")}
                  sx={{
                    minWidth: 36,
                    height: 36,
                    bgcolor: "black",
                    borderRadius: 0,
                    fontSize: 30,
                  }}
                >
                  +
                </Button>
              </Stack>
            </Stack>

            <Typography fontSize={26} fontWeight="bold" sx={{ mt: 2 }}>
              You will get{" "}
              <Typography
                component="span"
                fontSize={28}
                fontWeight="bold"
                color="#f3ba2e"
              >
                {(amount * rate).toLocaleString()} BYB
              </Typography>
            </Typography>

            <Typography fontSize={20} lineHeight={1.5}>
              {" "}
              <Typography component="span" fontWeight="bold" color="#f3ba2e">
                {parseFloat(totalSold).toLocaleString()} BYB / {parseFloat(hardCap).toLocaleString()} BYB
              </Typography>
            </Typography>
            <BorderLinearProgress
              sx={{ width: 600 }}
              variant="determinate"
              value={totalSold*100/hardCap}
            />

          {
            !account
            ? 
            <>
              <br/>
              <div sx={{minWidth:160}}>

                <ConnectButton/>
              </div>
            </>
            :
              Date.now() <= endTime * 1000
              ?
              <>

              <Button
                disabled={!whitelisted || isLoading || (startTime *1000 >= new Date()) || (new Date() >= endTime*1000)}
                onClick={isApproved || currency == "BNB"? buy:approve }
                sx={{
                  border: "1px solid #00ff75",
                  color: "#00ff75",
                  bgcolor: "#0e1f13",
                  minWidth: 160,
                  height: 52,
                  borderRadius: 0,
                  fontSize: 20,
                  mt: 3,
                  "&:hover": {
                    background: "#00ff75",
                    color: "white",
                  },
                }}
              >
                {whitelisted ? (isApproved || currency == "BNB" ? 'BUY': 'APPROVE'):'Your Wallet is not Whitelisted'}
                <ProgressLoader/>
              </Button>
              </>
              :""
          }

          {
            Date.now() >= nextClaimDate * 1000 && nextClaimAmount > 0
            ?
            <Button
              disabled={Date.now() <= nextClaimDate * 1000 || isLoading}
              onClick={claim}
              sx={{
                border: "1px solid #00ff75",
                color: "#00ff75",
                bgcolor: "#0e1f13",
                minWidth: 160,
                height: 52,
                borderRadius: 0,
                fontSize: 20,
                mt: 3,
                "&:hover": {
                  background: "#00ff75",
                  color: "white",
                },
              }}
            >
              {claimed?'You have already claimed.':`Claim ${parseFloat(nextClaimAmount).toLocaleString()} BYB`}
              <ProgressLoader/>
            </Button>
            :
            nextClaimAmount > 0
            ?
            <>
              <table border={1} style={{textAlign:'center', margin:'10px'}}>
                <tr>
                  <th style={{padding:'10px'}}>Next Claim In</th>
                  <th style={{padding:'10px'}}>Claim Amount</th>
                  <th style={{padding:'10px'}}>Total Locked</th>
                </tr>
                <tr>
                  <td>{`${countdown.days}:${countdown.hours}:${countdown.minutes}:${countdown.seconds}`}</td>
                  <td>{parseFloat(nextClaimAmount).toLocaleString()} BYB</td>
                  <td>{parseFloat(contribution).toLocaleString()} BYB</td>
                </tr>
              </table>
            </>
            :""
          }



            <Typography fontSize={16} lineHeight={1.5}>

              {" "}
              <Typography component="span" fontWeight="bold" color="#fff">
              * after our Launch you can claim your Token from this page
              </Typography>
            </Typography>
            <Stack alignItems="center" sx={{ mt: 5 }}>
              {/* <Typography fontSize={24} fontWeight="bold">
                The purchase of BYB will be ONLY in{" "}
                <Typography
                  fontSize={24}
                  component="span"
                  fontWeight="bold"
                  color="#f3ba2e"
                >
                  $BNB
                </Typography>
              </Typography> */}

              <Typography fontSize={18}>
                Rate:{" "}
                <Typography component="span" color="#fff">
                  1 {currency} = {rate.toLocaleString()} BYB
                </Typography>
              </Typography>
              
              <Typography fontSize={18}>
                Max Buy:{" "}
                <Typography component="span" color="#fff">
                  {Number(1250000).toLocaleString()} BYB
                </Typography>
              </Typography>
              <Typography fontSize={18}>
                Min Buy:{" "}
                <Typography component="span" color="#fff">
                  {Number(2500).toLocaleString()} BYB
                </Typography>
              </Typography>

              {/* <Typography fontSize={18}>
                Launch Price:{" "}
                <Typography component="span" color="#fff">
                  1 BNB = {(27000000).toLocaleString()}
                </Typography>
              </Typography> */}

              {/* <Typography fontSize={18}>
                Min. Buy:{" "}
                <Typography component="span" color="#fff">
                  1 BNB
                </Typography>
              </Typography> */}
              {/* <Typography fontSize={18}>
                Max. Buy:{" "}
                <Typography component="span" color="#fff">
                  10 BNB
                </Typography>
              </Typography> */}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
