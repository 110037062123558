import { useRef, useEffect } from "react";
// material
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
export default function PrivateSale() {
  const navigate = useNavigate();
  useEffect(() => {
   window.location.href = "https://bybarter.io"
  },[])
  return (
    <Stack alignItems="center" justifyContent="center">
      {/* <Typography variant="h1" sx={{ mt: 10 }}>
        Coming Soon...
      </Typography> */}
    </Stack>
  );
}
