// material
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Container,
  Stack,
  TextField,
} from "@mui/material";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { useEthers } from "@usedapp/core";
import { MetamaskErrorMessage } from "utils/MetamaskErrorMessage";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import formatBigNumber from "utils/formatBigNumber";

export default function Inventorypage() {
  const navigate = useNavigate();
  const { library, account } = useEthers();

  const [value, setValue] = useState(new Date("2014-08-18T21:11:54"));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const OwnedCharacter = await DwarTokenContract.tokenOfOwnerByIndex(
  //         account,
  //         0
  //       );
  //       setOwnedCharacter(formatBigNumber(OwnedCharacter));
  //     } catch (error) {
  //       setOwnedCharacter(null);
  //     }
  //   };
  //   if (account) fetchData();
  // }, [DwarCharacterContract, account]);

  return (
    <Box
    // sx={{
    //   height: "calc(100vh - 64px)",
    //   background: "url(/images/about4.png)",
    //   backgroundAttachment: "fixed",
    //   position: "relative",
    //   backgroundSize: "100% 120%",
    // }}
    >
      {/* <Stack
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 1,
          height: 1,
          bgcolor: "black",
          opacity: 0.77,
        }}
      /> */}
      <Container
        maxWidth="xl"
        sx={{ position: "relative", zIndex: 1, height: 1 }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: 1, height: 1 }}
        >
          <Stack sx={{ border: "2px solid #59f1f6", width: 1, pt: 4, pb: 5 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Box sx={{ height: "2px", width: 28, bgcolor: "primary.main" }} />
              <Typography>Admin Page</Typography>
              <Box sx={{ height: "2px", width: 28, bgcolor: "primary.main" }} />
            </Stack>

            {/* <Stack direction="row" sx={{ mx: 4 }}>
              <Stack alignItems="center">
                <Typography
                  align="center"
                  fontWeight="bold"
                  fontSize={20}
                  color="#59f1f6"
                  sx={{ my: 2 }}
                >
                  Set Presale Date
                </Typography>
                <MobileDatePicker
                  label="Presale Date"
                  inputFormat="MM/dd/yyyy"
                  value={value}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </Stack> */}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
